.party-workspace {
  place-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 60% !important;

  > * {
    position: absolute;
  }

  .songs {
    z-index: 4;
    filter: drop-shadow(5px -5px 10px #191919);
  }

  .title {
    z-index: 1;
    transform: translate(45%, -80%);

    @media (max-width: 400px) {
      transform: translate(35%, -100%);
    }
  }

  .family {
    z-index: 2;
    filter: blur(1.5px);
    transform: translate(-45%, -30%);

    @media (max-width: 400px) {
      transform: translate(-35%, -30%);
    }
  }

  .fan-club {
    z-index: 3;
    filter: blur(1.5px);
    transform: translate(45%, 80%);

    @media (max-width: 400px) {
      transform: translate(30%, 75%);
    }
  }

  .friends {
    z-index: 5;
    transform: translate(-50%, 112%);
    filter: drop-shadow(5px -5px 10px #191919);

    @media (max-width: 400px) {
      transform: translate(-28%, 110%);
    }
  }

  @media (max-width: 767px) {
    scale: 0.8;
    height: 60vh;
    max-height: 25rem;
  }
}

.workspace-grid {
  max-height: 600px;
  @media (max-width: 767px) {
    height: 70vh;
    gap: unset;
  }
}

.workspace-grid > * {
  min-width: 30%;
  max-width: 60%;

  @media (max-width: 767px) {
    width: unset;
    min-width: unset;
    max-width: unset;
  }
}
