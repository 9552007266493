@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

/* General Styles */
body {
  background: linear-gradient(135deg, #101010, #101010, #470f40);
  margin: 0;
  background-color: #101010;
  background-size: cover; /* Ensures the background covers the entire viewport */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-attachment: fixed;
  overflow-x: hidden;
}

* {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: white;
}

.page {
  display: flex;
  height: 100svh;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

a {
  text-decoration: none;
  color: white;
}

.header-home {
  width: 100%;
  padding: 2rem;
  display: flex;
  color: white;
  background-color: #101010;
  justify-content: space-between;
  box-sizing: border-box;
  top: 0;
  position: sticky;
  z-index: 1000;
}

footer {
  font-size: 0.7rem;
  justify-items: center;
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-buttons {
    display: flex !important;
    gap: 1rem;
    flex-direction: row;
    text-align: center;
  }
}

/* Responsive Design */

@media (max-width: 768px) {
  footer {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 2rem;
    gap: 1rem;
    margin-inline: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .flag-container {
    display: block;
    min-width: 6rem !important;
    height: auto;
  }

  .disclaimer {
    position: unset !important;
    display: unset !important;
    bottom: unset;
    padding: unset !important;
  }

  .large {
    font-size: 1.7rem !important;
  }

  .regular {
    font-size: 1rem !important;
    padding-bottom: 0.8rem !important;
  }
}

@media (max-height: 700px) {
  .large {
    font-size: 1.4rem !important;
  }
  .regular {
    font-size: 0.9rem !important;
  }
}

.flag-container {
  position: relative;
  display: inline-block;
  width: 6rem; /* Set the width and height to suit your needs */
  height: auto;
  overflow: hidden; /* Ensures anything outside the container is hidden */
  border-radius: 0.5rem;
  min-width: 6rem !important;
  height: auto;
}

.flag-img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: inherit;
}

.content {
  gap: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclaimer {
  font-size: 0.55rem;
  color: #888888;
  text-align: center;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  padding: 1rem;
  left: 0;
}

.large {
  font-weight: 700;
  font-size: 2.7rem;
}

.regular {
  text-transform: none !important;
  font-size: 1.2rem;
}

.songs {
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}

.song-item {
  background-color: #191919;
  display: inline-flex;
  position: relative;
  padding: 0.4rem;
  width: 19rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  box-sizing: border-box;

  .song-item-left {
    flex-direction: "column";
    display: flex;
    align-items: center;
  }

  .song-main {
    font-weight: 700;
    font-size: 0.8rem;
  }

  .song-sub {
    font-weight: 300;
    font-size: 0.65rem;
  }

  .flag-container {
    display: block;
    min-width: 3.5rem !important;
    width: 3.5rem !important;
    height: auto;
    scale: 0.7;
  }
}

.points {
  background: linear-gradient(to bottom right, #8800c8, #c848c3);
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
  aspect-ratio: 1;
  border-radius: 5px;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.noise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("assets/images/noise.png");
  opacity: 0.35;
  background-repeat: repeat;
  background-size: 20% 20%;
  z-index: 3;
  pointer-events: none; /* Noise shouldn't block user interactions */
}

.song-position {
  font-size: 1.5rem;
  font-weight: 700;
  background-color: #ffffff;
  font-weight: 700;
  font-size: 0.6rem;
  padding: 0.1rem 0.3rem;
  aspect-ratio: 1;
  border-radius: 5px;
  color: #090909;
}

.label-text {
  background: #fff;
  color: #090909;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.05rem 0.3rem;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
}
