.download-button {
  margin-top: 1rem;
  padding: 10px 15px;
  background-color: #dddddd;
  color: #191919;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  letter-spacing: 0.05rem;
  align-items: center;
  display: flex;
  gap: 0.3rem;

  @media (max-width: 530px) {
    font-size: 0.75rem !important;
    margin-top: 0.5rem !important;
  }
}
