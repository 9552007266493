.country-container {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.country-container.exiting {
  animation: fadeOutUp 1s forwards;
}

.country-container:not(.exiting) {
  animation: fadeInUp 1s forwards;
}

.points-container {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.points-container:not(.exiting) {
  animation: fadeInUp 1.5s forwards;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
