.stats-tile {
  z-index: 4;
  filter: drop-shadow(0px -0px 10px #09090999);

  .stats-tile-title {
    align-self: center;
  }

  .song-item {
    width: 100%;
  }

  @media (max-width: 400px) {
    scale: 0.9;
  }
}

.poland,
.portugal {
  z-index: 3;
  filter: blur(1.5px);

  > * {
    text-align: center;
  }
}

.poland {
  transform: translateX(42%);

  @media (max-width: 820px) {
    transform: translate(30%, 80%);
  }
}

.portugal {
  transform: translateX(-42%);

  @media (max-width: 820px) {
    transform: translate(-30%, -80%);
  }
}

.fade-out {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
}
