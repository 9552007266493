.markdown-content {
  padding: 2rem;

  li,
  span,
  p,
  strong {
    text-transform: none;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  a {
    text-transform: none !important;
    text-decoration: unset !important;
  }
}
