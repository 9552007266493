.grid-container {
  width: 90%;
  max-width: 1000px;
  flex-direction: row;
  display: flex;
  gap: 2rem;
  justify-content: center;
  height: 60vh;

  @media (max-width: 767px) {
    flex-direction: column;
    height: unset;
  }

  .stage-bg {
    background-image: url("../assets/images/2024-stage-wide.png");
    background-position: center;
    background-size: cover;
    padding: 3rem;
    padding-top: 4rem;
    box-sizing: border-box;
    border-radius: 20px;
    aspect-ratio: 0.8;
    max-width: 100%;

    * {
      justify-content: center;
    }

    @media (max-width: 767px) {
      padding-top: 2rem;
      .country-pool {
        scale: 0.8;
      }
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 30;
  }
}

.grid-container.ranking {
  height: unset !important;
}
