.marquee-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  .text-container {
    text-align: center;
    width: 90%;
  }
}
